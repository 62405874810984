import React from "react";
import Heading from "components/core/Typography/Heading";
import Service from "templates/service";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  PINTEREST_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from "constants/social";

export interface GDPRProps {
  location: Location;
}

export default ({ location }: GDPRProps) => {
  const { t } = useTranslation();

  return (
    <Service
      title={"GDPR"}
      subtitle={"Last updated on 11th February 2021"}
      seoProps={{
        title: t("pages.gdpr.seo.title"),
        description: t("pages.gdpr.seo.description"),
        image: {
          relativePath: "meta/customerly-live-chat-gdpr.jpg",
          alt: "Customerly and GDPR",
        },
        structuredData: [
          {
            "@context": "https://schema.org",
            "@type": "Corporation",
            brand: "Customerly",
            name: "Live Chat Software",
            sameAs: [
              FACEBOOK_URL,
              INSTAGRAM_URL,
              LINKEDIN_URL,
              PINTEREST_URL,
              TWITTER_URL,
              YOUTUBE_URL,
            ],
            url: "https://www.customerly.io/",
          },
        ],
      }}
      location={location}
    >
      <Heading level={1}>What is GDPR?</Heading>
      <p>
        The EU General Data Protection Regulation (“GDPR”) is a new data
        protection law that came into effect on May 25, 2018. It replaced
        existing EU Data Protection law to strengthen the protection of
        “personal data” and the rights of the individual.
      </p>

      <Heading level={2}>Does it affect me?</Heading>
      <p>
        If you hold or process the data of an any person in the EU, the GDPR
        will apply to you, whether you’re based in the EU or not.
      </p>
      <Heading level={2}>
        Which Customerly’s features are useful to comply with the GDPR?
      </Heading>
      <p>
        Our team has already build and it’s still building useful features that
        enable our customers to easily meet their GDPR obligations, such as:
        <ul>
          <li>Access Controls for users with feature-granularity</li>
          <li> Active sessions list with the following information:</li>
          <ul>
            <li> Session creation date</li>
            <li> Last activity date</li>
            <li> IP address</li>
            <li> Operating system</li>
            <li> Browser</li>
            <li> Location (estimation based on IP)</li>
          </ul>
          <li> Access logs with the following information:</li>
          <ul>
            <li> Date</li>
            <li> IP address</li>
            <li> Operating system</li>
            <li> Browser</li>
            <li> Login result (granted or denied)</li>
          </ul>
          <li>
            Password history control, with password change date, IP address,
            strength and length
          </li>
          <li> Export of user data to meet data portability requirements</li>
          <li>Permanently deletion of any data referencing to a single user</li>
          <li> Automatic opt-out in any campaign or automation</li>
        </ul>
      </p>
      <Heading level={2}>Processing of personal data using Customerly</Heading>
      <p>
        The data processing through our software is done in accordance with the
        <Link to="/assets/legals/DPA.pdf">
          {" "}
          Data Processing Agreement (DPA)
        </Link>{" "}
        .
      </p>
      <Heading level={3}>Processing of the customer's personal data</Heading>
      <p>
        Our customers’ data is handled with the utmost care, in accordance with
        the <Link to="/privacy/">Privacy Policy</Link>. Any consents provided by
        customers for treatments other than contractual purposes can be revoked
        at any time:
        <ul>
          <li> Accessing the customer support.</li>
          <li>
            Through the appropriate link placed at the bottom of each e-mail we
            send through automatic procedures.
          </li>
        </ul>
      </p>
      <Heading level={3}>GDPR References</Heading>
      <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&from=EN">
        EU Regulation 2016/679
      </a>
      <Heading level={3}>Contacts</Heading>
      <p>
        If there are any questions regarding this GDPR Policy, you may contact
        us using the information below. <br />
        Customerly Limited
        <br />
        Ground Floor, 71 Lower Baggot Street <br />
        Dublin <br />
        D02 P593 <br />
        legal@customerly.io <br />
        +353830399505 <br />
        Last updated 24 May 2018 <br />
      </p>
    </Service>
  );
};
